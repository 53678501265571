<template>
  <Popup>
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-3 green-text bold font19">
        Enter the required payment details
      </div>
      <div class="col-12 text-center mb-3">
        <div id="card-element"></div>
      </div>
      <!-- <div class="col-12 text-center mb-3">
        <div id="address-element"></div>
      </div> -->
      <div class="col-6 col-md-auto px-1 px-md-3">
        <Button color="red" width="100" btnText="Cancel" icon="arrow" @buttonClicked="cancelPayment">
          <ios-close-icon class="inline-icon" />
        </Button>
      </div>
      <div class="col-6 col-md-auto px-1 px-md-3">
        <Button color="green" width="100" btnText="Continue" icon="arrow" @buttonClicked="confirmPayment" :isLoading="isLoading" >
          <IconArrowForward />
        </Button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default ({
  name: 'StripePaymentForm',
  components: {
    Popup: defineAsyncComponent(() => import('../Popup.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  props: ['ukPaymentDetails'],
  data () {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_KEY,
      stripe: '',
      elements: '',
      card: '',
      // address: '',
      isLoading: false
    }
  },
  mounted () {
    this.includeStripe('js.stripe.com/v3/', function () {
      this.configureStripe()
    }.bind(this))
  },
  computed: {
    ...mapGetters(['stripeClientSecret'])
  },
  methods: {
    ...mapActions(['cancelStripePayment']),
    /*
        Includes Stripe.js dynamically
    */
    includeStripe (URL, callback) {
      const documentTag = document; const tag = 'script'
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = '//' + URL
      if (callback) { object.addEventListener('load', function (e) { callback(null, e) }, false) }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },
    configureStripe () {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.stripeAPIToken)
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', { hidePostalCode: true, iconStyle: 'solid' })
      this.card.mount('#card-element')
      // this.address = this.elements.create('address', {
      //   mode: 'billing',
      //   defaultValues: {
      //     name: this.ukPaymentDetails.firstName + ' ' + this.ukPaymentDetails.lastName,
      //     address: {
      //       line1: this.ukPaymentDetails.addressLine,
      //       city: this.ukPaymentDetails.town,
      //       postal_code: this.ukPaymentDetails.postCode,
      //       country: this.ukPaymentDetails.country
      //     }
      //   }
      // })
      // this.address.mount('#address-element')
    },
    async confirmPayment () {
      this.isLoading = true
      const ret = await this.stripe.confirmCardPayment(this.stripeClientSecret, {
        payment_method: {
          card: this.card
          // billing_details: {
          //   name: this.address.name,
          //   address: this.address.address
          // }
        }
      }).then(function (result) {
        console.log(result)
        return result
        // Handle result.error or result.paymentIntent
      })
      console.log('RESULT, ret', ret)
      this.isLoading = false
      this.$router.push('/paymentcompleteUk')
    },
    cancelPayment () {
      this.card.clear()
      this.card.destroy()
      this.cancelStripePayment()
      this.$emit('close')
    }
  }
})
</script>
